@import url("https://use.typekit.net/gvm7sim.css");

html, body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: "elza", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5 {
  font-family: "proxima-soft", sans-serif;
  font-weight: 800;
  color: #dd5329;
}

h1, h2, h3, h4, h5, p {
  margin: 0;
}

h1 {
  font-size: 3em;
}

h2 {
  text-transform: uppercase;
  font-size: 2.2em;
}

p, ul {
  font-size: 1.35em;
  line-height: 1.5;
  color: #888
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  height: 100%;
}

.content-container {
  padding: 5em;
}

.content-container-column {
  padding: 10vh 0 10vh 10vh;
}

.content-container-column-end {
  padding: 10vh 10vh 10vh 0;
}

.color-bg {
  background: rgb(221,83,41);
  background: linear-gradient(25deg, rgba(221,83,41,1) 0%, rgba(221,133,36,1) 100%);
}

.color-bg h2, .color-bg a {
  color: #fff;
}

.color-bg p {
  color: rgb(255, 176, 151);
}

.no-vpadding {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}