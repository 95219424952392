#welcome {
    background: rgb(255,255,255);
background: linear-gradient(320deg, rgba(220,220,220,1) 0%, rgba(255,255,255,1) 80%);
}

#welcome .column {
    justify-content: center;
    height: 80vh;
}

#welcome .wrapper {
    margin: auto;
    width: 80%;
}

#welcome img {
    display: block;
    width: 100%;
    height: auto;
    margin: auto;
}