.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin: 1em 0 5em;
}

.gallery-item {
    flex-basis: calc(25% - 10px);
}

.gallery-item img {
    object-fit: cover;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border-radius: 10px;
    cursor: pointer;
}

.gallery-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    display: none;
    flex-direction: column;
}

.gallery-spotlight {
    width: 100%;
    height: 100%;
    background-color: #000;
    position: relative;
}

.gallery-container .row {
    height: 100%;
}

.gallery-spotlight #featured iframe,
.gallery-spotlight #featured img {
    display: block;
    max-width: 80%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    aspect-ratio: 16/9;
    overflow: hidden;
}

.gallery-spotlight #close-btn,
.gallery-spotlight #expand-btn {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 24px;
    cursor: pointer;
    display: block;
    opacity: 20%;
}

.gallery-spotlight #expand-btn {
    right: 64px;
}

.gallery-spotlight #back, .gallery-spotlight #next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 20%;
}

.gallery-spotlight #back:hover,
.gallery-spotlight #next:hover,
.gallery-spotlight #close-btn:hover,
.gallery-spotlight #expand-btn:hover {
    opacity: 100%;
}

.gallery-spotlight #back {
    left: 24px;
}

.gallery-spotlight #next {
    right: 24px;
}

.gallery-item-comments {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 30%;
    background-color: #fff;
    padding: 32px;
    overflow: scroll;
    background: linear-gradient(25deg, #151515 0%, #111 100%);
    border-left: 1px solid #181818;
    display: none;
}

.gallery-item-comments > div {
    margin-bottom: 48px;
}

.gallery-item-comments p {
    margin-top: 16px;
    margin-bottom: 16px;
}

.gallery-item-comments a {
    color: #fff;
}